<template>
  <!-- <div class="row">
    <div class="card col-md-10">
      <div class="card-body"> -->
  <!-- <GoogleMap
                ref="mapRef"
                api-key="AIzaSyAOPKP-1DzeHX82Ehu9r-A2rf3ozs7f17I"
                style="width: 100%; height: calc(100vh - 200px)"
                :center="center"
                :zoom="12">
                <template #default="{ ready, api, map, mapTilesLoaded }">
                    <Marker
                    v-for="driver in driverShownOnMap"
                    :key="driver.id"
                    :options="{
                        position: { lat: driver.latitude, lng: driver.longitude },
                        icon: {
                        url: '/media/icons/map/car.png',
                        scaledSize: { width: 40, height: 40 }
                        }
                    }">
                    <InfoWindow :options="{ content: driver.name }" />
                    </Marker>
                </template>
                </GoogleMap> -->
  <!-- <div id="map">
          <div
            id="mapContainer"
            ref="mapRef"
            style="width: 100%; height: calc(100vh - 200px)"
          ></div>
        </div> -->
  <!-- </div>
    </div>
  </div> -->
  <div id="map">
    <div
      id="mapContainer"
      ref="mapRef"
      style="width: 100%; height: calc(100vh - 200px)"
    ></div>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import { getAllDriver } from "@/api/driver.api";

export default {
  setup() {
    const center = { lat: 1.3521, lng: 103.8198 };
    const mapRef = ref(null);
    const platform = ref();
    const drivers = ref([]);

    var map;
    var bubble;
    var ui;

    onMounted(async () => {
      const response = await getAllDriver();
      drivers.value = response.data;

      const herePlatform = new window.H.service.Platform({
        apikey: "AU9WJNuC-1s67CJSAFiJPAVOCqUIpmJKRVALXB8nh-k",
      });
      platform.value = herePlatform;
      initializeHereMap();
      initializeDriverMarker(drivers.value);
    });

    function initializeHereMap() {
      const mapContainer = mapRef.value;
      const H = window.H;
      var maptypes = platform.value.createDefaultLayers();

      map = new H.Map(mapContainer, maptypes.vector.normal.map, {
        zoom: 12,
        center: center,
      });

      addEventListener("resize", () => map.getViewPort().resize());

      // add behavior control
      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));

      // add UI
      ui = H.ui.UI.createDefault(map, maptypes);
      // End rendering the initial map
    }

    function initializeDriverMarker(drivers) {
      drivers.forEach((driver) => {
        let marker = new H.map.Marker(
          { lat: driver.latitude, lng: driver.longitude },
          {
            icon: new H.map.Icon("/media/map/car.png", {
              size: { w: 40, h: 40 },
            }),
          }
        );
        marker.name = driver.name;

        marker.addEventListener(
          "tap",
          function (evt) {
            openBubble(evt.target.getGeometry(), evt.target.name);
          },
          false
        );

        map.addObject(marker);
      });
    }

    function openBubble(position, text) {
      if (!bubble) {
        bubble = new H.ui.InfoBubble(
          position,
          // The FO property holds the province name.
          { content: text }
        );
        ui.addBubble(bubble);
      } else {
        bubble.setPosition(position);
        bubble.setContent(text);
        bubble.open();
      }
    }

    return {
      mapRef,
    };
  },
};
</script>
